<template>
  <div :is="pointData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="pointData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Funcionalidade não encontrada
        <b-link class="alert-link" :to="{ name: 'apps-point-list' }">
          Lista de funcionalidades
        </b-link>
        for other point.
      </div>
    </b-alert>

    <template v-if="pointData">
      <div>
        <b-card>
          <h2 class="mb-2">{{ $t("pointDetails") }}</h2>
          <b-row>
            <b-col md="5">
              <b-form-group label="Nome" label-for="namePoint">
                <template v-slot:label>
                  {{ $t("message.point.user") }}
                </template>
                <b-form-input v-model="pointData.userName" disabled />
                <!-- multiple select -->
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group label="Data" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.date") }}
                </template>
                <b-form-input
                  :value="getDay(new Date(pointData.startDay))"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Hours" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.total") }}
                </template>
                <b-form-input
                  v-if="pointData.total"
                  v-model="pointData.total"
                  disabled
                />
                <b-form-input
                  v-else
                  :value="getColLabel('notFinished')"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Identificador" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.startDay") }}
                </template>
                <b-form-input
                  v-if="pointData.startDay"
                  :value="getHour(new Date(pointData.startDay))"
                  disabled
                />
                <b-form-input v-else :value="getColLabel('notDone')" disabled />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Identificador" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.late") }}
                </template>
                <b-form-input
                  v-if="pointData.startLate != null"
                  :value="getTrueOrFalse(pointData.startLate)"
                  disabled
                />
                <b-form-input v-else :value="getColLabel('notDone')" disabled />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Identificador" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.endDay") }}
                </template>
                <b-form-input
                  v-if="pointData.endDay"
                  :value="getHour(new Date(pointData.endDay))"
                  disabled
                />
                <b-form-input v-else :value="getColLabel('notDone')" disabled />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Identificador" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.late") }}
                </template>
                <b-form-input
                  v-if="pointData.endLate != null"
                  :value="getTrueOrFalse(pointData.endLate)"
                  disabled
                />
                <b-form-input v-else :value="getColLabel('notDone')" disabled />
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="pointData.startLate">
              <b-form-group label="Identificador" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.reason") }}
                </template>
                <b-form-input
                  v-if="pointData.startLateReason"
                  :value="pointData.startLateReason"
                  disabled
                />
                <b-form-input v-else :value="getColLabel('notDone')" disabled />
              </b-form-group>
            </b-col>
            <b-col md="6" v-else> </b-col>

            <b-col md="6" v-if="pointData.endLate">
              <b-form-group label="Identificador" label-for="membersPoint">
                <template v-slot:label>
                  {{ $t("message.point.reason") }}
                </template>
                <b-form-input
                  v-if="pointData.endLateReason"
                  :value="pointData.endLateReason"
                  disabled
                />
                <b-form-input v-else :value="getColLabel('notDone')" disabled />
              </b-form-group>
            </b-col>
            <b-col md="6" v-else> </b-col>
          </b-row>

          <b-row v-if="restrictions('button_approve_point')">
            <b-col>
              <div class="text-right">
                <b-button
                  v-if="
                    pointData.approved === false ||
                    pointData.approved === null ||
                    pointData.approved === undefined
                  "
                  variant="outline-success"
                  class="ml-1"
                  @click="approve()"
                >
                  {{ $t("message.point.approve") }}
                </b-button>
              </div>
            </b-col>
          </b-row>

          <br />

          <l-map :zoom="zoom" :center="center">
            <l-tile-layer :url="url" />
            <v-marker-cluster :options="clusterOptions">
              <l-marker
                v-if="startDay"
                :lat-lng="startDayLatLong"
                :icon="startDayIcon"
              >
                <l-popup>{{
                  $t("message.point.startDay") +
                  ": " +
                  getHour(new Date(pointData.startDay))
                }}</l-popup>
              </l-marker>
              <l-marker
                v-if="endDay"
                :lat-lng="endDayLatLong"
                :icon="endDayIcon"
              >
                <l-popup>{{
                  $t("message.point.endDay") +
                  ": " +
                  getHour(new Date(pointData.endDay))
                }}</l-popup>
              </l-marker>
            </v-marker-cluster>
          </l-map>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          Você tem certeza que deseja deletar a funcionalidade ?
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-point-list' }"
            @click="deletePoint()"
          >
            Deletar
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dateInput from "@/@core/utils/dateInput";
import { LMap, LTileLayer, LMarker, LCircle, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import iconStartDay from "@core/leaflet/startDay.png";
import iconEndDay from "@core/leaflet/endDay.png";
import "@core/leaflet/MarkerCluster.css";
import "@core/leaflet/MarkerCluster.Default.css";
import store from "@/store";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    //leaftlet
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },

  data() {
    return {
      userData: store.state.user.userData,

      pointData: [],

      startDayLatLong: [],
      startDay: false,
      endDayLatLong: [],
      endDay: false,

      startDayIcon: L.icon({
        iconUrl: iconStartDay,
        iconSize: [42, 42],
      }),

      endDayIcon: L.icon({
        iconUrl: iconEndDay,
        iconSize: [42, 42],
      }),

      clusterOptions: {},

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 10,
      center: [-23.4819674, -46.8543619],
      circle: {
        radius: 150,
        zoom: 100,
        color: "#EA5455",
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  async created() {
    await this.getData();
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    async getData() {
      axios({
        method: "get",
        url: `${URL_API}point/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.pointData = response.data;

          if (response.data.locationStartDay) {
            if (response.data.locationStartDay.latitude != 0) {
              this.startDayLatLong = [
                response.data.locationStartDay.latitude,
                response.data.locationStartDay.longitude,
              ];
              this.center = [
                response.data.locationStartDay.latitude,
                response.data.locationStartDay.longitude,
              ];
              this.startDay = true;
            }
          }
          if (response.data.locationEndDay) {
            if (response.data.locationEndDay.latitude != 0) {
              this.endDayLatLong = [
                response.data.locationEndDay.latitude,
                response.data.locationEndDay.longitude,
              ];
              this.endDay = true;
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.pointData = undefined;
          }
        });
    },

    async approve() {
      axios({
        method: "put",
        url: `${URL_API}point/${this.pointData.id}/approve`,
        headers: getHeader(this.userData),
        data: this.pointData,
      }).then((response) => {
        this.pointData = [];
        this.getData();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sucesso",
            icon: "CheckIcon",
            variant: "success",
            text: "Ponto aprovado com sucesso!",
          },
        });
      });
    },

    getTrueOrFalse(value) {
      if (value) {
        return "Sim";
      } else {
        return "Não";
      }
    },

    getColLabel(colName) {
      return this.$i18n.t(colName);
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(new Date(value), "short", this.$i18n.locale);
      }
      return this.$i18n.t("message." + "noDate");
    },

    getHour(date) {
      return (
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
      );
    },

    isValid(value) {
      return value.getTime() === value.getTime();
    },

    getDay(day) {
      var d = new Date(day);
      if (this.isValid(d)) {
        return this.localeDate(day);
      } else {
        return "";
      }
    },

    deletePoint() {
      axios({
        method: "delete",
        url: `${URL_API}point/${this.pointData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A funcionalidade foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            pointData.value = undefined;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 550px;
  }
}
</style>
